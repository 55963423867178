// @flow

import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import AesUtil from '../../../../modules/UI/util/AesUtil';

import { setPassword } from '../../base/conference';
import { Dialog } from '../../base/dialog';
import { translate } from '../../base/i18n';
import { connect } from '../../base/redux';
import { _cancelPasswordRequiredPrompt } from '../actions';

/**
 * The type of the React {@code Component} props of
 * {@link PasswordRequiredPrompt}.
 */
type Props = {

    /**
     * The JitsiConference which requires a password.
     */
    conference: Object,

    /**
     * The redux store's {@code dispatch} function.
     */
    dispatch: Dispatch<any>,

    /**
     * The translate function.
     */
    t: Function
};

/**
 * The type of the React {@code Component} state of
 * {@link PasswordRequiredPrompt}.
 */
type State = {

    /**
     * The password entered by the local participant.
     */
    password: string
}

/**
 * Implements a React Component which prompts the user when a password is
 * required to join a conference.
 */
class PasswordRequiredPrompt extends Component<Props, State> {
    state = {
        password: ''

        //Bizwell.
        , okDisabled : true
    };

    /**
     * Initializes a new PasswordRequiredPrompt instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        // Bind event handlers so they are only bound once per instance.
        this._onPasswordChanged = this._onPasswordChanged.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        //Bizwell.
        // return (
        //     <Dialog
        //         disableBlanketClickDismiss = { true }
        //         isModal = { false }
        //         onCancel = { this._onCancel }
        //         onSubmit = { this._onSubmit }
        //         titleKey = 'dialog.passwordRequired'
        //         width = 'small'>
        //         { this._renderBody() }
        //     </Dialog>
        // );
        return (
            <Dialog
                okDisabled = { this.state.okDisabled }
                disableBlanketClickDismiss = { true }
                isModal = { false }
                onCancel = { this._onCancel }
                onSubmit = { this._onSubmit }
                titleKey = 'dialog.passwordRequired'
                width = 'small'>
                { this._renderBody() }
            </Dialog>
        );
    }

    /**
     * Display component in dialog body.
     *
     * @returns {ReactElement}
     * @protected
     */
    _renderBody() {
        //Bizwell.
        // return (
        //     <div>
        //         <TextField
        //             autoFocus = { true }
        //             compact = { true }
        //             label = { this.props.t('dialog.passwordLabel') }
        //             name = 'lockKey'
        //             onChange = { this._onPasswordChanged }
        //             shouldFitContainer = { true }
        //             type = 'text'
        //             value = { this.state.password } />
        //     </div>
        // );
        return (
            <div>
                <div className="description-label"><span>{ this.props.t('dialog.passwordLabel') }</span></div>
                <div className="eb_input fill">
                    <input type="password" value = { this.state.password || ""} onChange = { this._onPasswordChanged }/>
                </div>
            </div>
        );
    }

    _onPasswordChanged: ({ target: { value: * }}) => void;

    /**
     * Notifies this dialog that password has changed.
     *
     * @param {Object} event - The details of the notification/event.
     * @private
     * @returns {void}
     */
    _onPasswordChanged({ target: { value } }) {
        //Bizwell.
        // this.setState({
        //     password: value
        // });
        this.setState({
            okDisabled: !(value.length > 0), 
            password: value
        });
    }

    _onCancel: () => boolean;

    /**
     * Dispatches action to cancel and dismiss this dialog.
     *
     * @private
     * @returns {boolean}
     */
    _onCancel() {

        this.props.dispatch(
            _cancelPasswordRequiredPrompt(this.props.conference));

        return true;
    }

    _onSubmit: () => boolean;

    /**
     * Dispatches action to submit value from this dialog.
     *
     * @private
     * @returns {boolean}
     */
    _onSubmit() {
        const { conference } = this.props;

        // We received that password is required, but user is trying anyway to
        // login without a password. Mark the room as not locked in case she
        // succeeds (maybe someone removed the password meanwhile). If it is
        // still locked, another password required will be received and the room
        // again will be marked as locked.

        //Bizwell.
        // this.props.dispatch(
        //     setPassword(conference, conference.join, this.state.password));
        this.props.dispatch(
            setPassword(conference, conference.join, AesUtil.encrypt("_xMeet_key", this.state.password)));

        // We have used the password so let's clean it.
        this.setState({
            password: undefined
        });

        return true;
    }
}

export default translate(connect()(PasswordRequiredPrompt));
