import React, { Component } from 'react';
import { translate } from '../../base/i18n/functions';
import _reactDom from 'react-dom';

class DeviceSelectorItem extends Component<Props> {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false
        }
    }

    componentDidMount() {
        document.addEventListener('click', this._onHandleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this._onHandleClickOutside);
    }

    componentDidUpdate(prevProps) {
    
    }

    _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

    _onHandleClickOutside = (event) => {
        if (event.defaultPrevented) return;

        if (this.state.isActive) {
            var _reactDom2 = this._interopRequireDefault(_reactDom);
            var domNode = _reactDom2.default.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
            if (!domNode || event.target instanceof Node && !domNode.contains(event.target)) {
                this.setState({ isActive: false });
            }
        }
    }

    _onClick = () => {
        this.setState({ isActive: true });
    }

    _onSelect = (item) => {
        this.setState({ isActive: false });
        this.props.onSelect(item);
    }

    _renderDropdownItem() {
        const items = this.props.items;

        return (
            items.map((item, index) => (
                <div 
                    key = { index } 
                    className = 'item' 
                    onClick = { () => { this._onSelect(item) } }>
                    { item.content }
                </div>
            ))
        )
    }

    render() {
        if(this.props.isDisabled){
            return (
                <div className = 'fake_select'>
                    <div className = 'selector_trigger disabled'>
                        <span>{this.props.triggerText}</span>
                        <button type = "button" className = "eb_btn icon_btn icon_select_off"></button>
                    </div>
                </div>
            )
        }

        return (
            <div className = 'fake_select'>
                <div className = { `selector_trigger ${ this.state.isActive ? 'active' : ''}` } onClick = { this._onClick }>
                    <span>{this.props.triggerText}</span>
                    <button type = "button" className = {`eb_btn icon_btn ${ this.state.isActive ? 'icon_select_on' : 'icon_select_off' }`}></button>
                </div>
                <div className = 'droplist'>
                    { this._renderDropdownItem() }
                </div>
            </div>
        )
    }
}

export default translate(DeviceSelectorItem);