// @flow

import { WELCOME_PAGE_ENABLED, getFeatureFlag } from '../base/flags';
import { toState } from '../base/redux';

import { ajaxJsonData } from '../xclick/common';

declare var APP: Object;

/**
 * Determines whether the {@code WelcomePage} is enabled by the app itself
 * (e.g. Programmatically via the Jitsi Meet SDK for Android and iOS). Not to be
 * confused with {@link isWelcomePageUserEnabled}.
 *
 * @param {Function|Object} stateful - The redux state or {@link getState}
 * function.
 * @returns {boolean} If the {@code WelcomePage} is enabled by the app, then
 * {@code true}; otherwise, {@code false}.
 */
export function isWelcomePageAppEnabled(stateful: Function | Object) {
    if (navigator.product === 'ReactNative') {
        // We introduced the welcomePageEnabled prop on App in Jitsi Meet SDK
        // for Android and iOS. There isn't a strong reason not to introduce it
        // on Web but there're a few considerations to be taken before I go
        // there among which:
        // - Enabling/disabling the Welcome page on Web historically
        // automatically redirects to a random room and that does not make sense
        // on mobile (right now).
        return Boolean(getFeatureFlag(stateful, WELCOME_PAGE_ENABLED));
    }

    return true;
}

/**
 * Determines whether the {@code WelcomePage} is enabled by the user either
 * herself or through her deployment config(uration). Not to be confused with
 * {@link isWelcomePageAppEnabled}.
 *
 * @param {Function|Object} stateful - The redux state or {@link getState}
 * function.
 * @returns {boolean} If the {@code WelcomePage} is enabled by the user, then
 * {@code true}; otherwise, {@code false}.
 */
export function isWelcomePageUserEnabled(stateful: Function | Object) {
    return (
        typeof APP === 'undefined'
            ? true
            : toState(stateful)['features/base/config'].enableWelcomePage);
}

/**
 * Bizwell. 나의 화상회의 목록
 */
export function loadRecentJoinRoom(dispatch, id, locale) {
	const callbackFn = function(data) {
		data.map((obj)=>{
			let time = new Date(Number(obj.creationdate));
			//js date의 getMonth()는 return 값이 0부터 11까지이므로 +1을 해줌
			const year = time.getFullYear();
			const month = time.getMonth() + 1;
			const date = time.getDate();
			const hours = time.getHours();
			const minutes = time.getMinutes();
			
			obj.date = year
				+ "-" + ( month < 10 ? "0" + month : month )
				+ "-" + ( date < 10 ? "0" + date : date );
			
			obj.time = ( hours < 10 ? "0" + hours : hours )
				+ ":" + ( minutes < 10 ? "0" + minutes : minutes )
		})
		dispatch({type:'SET_USER_RECENT_JOIN_ROOM', recentJoinRoom: data || [] });
	};
	ajaxJsonData({
		facade: "RoomFacade",
		command: "getRecentJoinRoomAjax",
		ID: id,
		locale: locale,
	}, callbackFn.bind(this));
}

export function deleteRecentJoinRoom(loginId, roomId, name, callbackFn) {
	ajaxJsonData({
		facade: "RoomFacade",
		command: "deleteRecentJoinRoomAjax",
		ID: loginId,
		roomId: roomId,
		name: name,
		transaction_yn: "Y",
	}, callbackFn);
}
