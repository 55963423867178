// @flow
import React from 'react';

import { AbstractDialogTab } from '../../../base/dialog';
import { translate } from '../../../base/i18n';

declare var APP: Object;

/**
 * The type of the React {@code Component} props of {@link ProfileTab}.
 */
export type Props = {
    ...$Exact<AbstractDialogTabProps>,
    /**
     * The password to display for the local participant.
     */
    password: string,


    passwordConfirm: string,

    /**
     * The subject to display for the local participant.
     */
    subject: string,


    isAdmin: boolean,

    isLock: boolean,
    /**
     * Invoked to obtain translated strings.
     */
    t: Function
}

/**
 * React {@code Component} for modifying the local user's profile.
 *
 * @extends Component
 */
class RoomTab extends AbstractDialogTab<Props> {
    /**
     * Initializes a new {@code ConnectedSettingsDialog} instance.
     *
     * @param {Props} props - The React {@code Component} props to initialize
     * the new {@code ConnectedSettingsDialog} instance with.
     */
    constructor(props: Props) {
        super(props);

        // this.state = {
        //     roomPassword : '',
        //     roomPasswordConfirm : '',
        //     useRoomPassword : this.props.isLock
        // }

        this._onUseRoomPassword = this._onUseRoomPassword.bind(this);
        this._onRoomPassword = this._onRoomPassword.bind(this);
        this._onRoomPasswordConfirm = this._onRoomPasswordConfirm.bind(this);
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const {
            password,
            passwordConfirm,
            isAdmin,
            subject,
            isLock,
            t
        } = this.props;
        const roomPassword_required = isLock && (password === undefined || password == "") ;
        const roomPasswordConfirm_not_match = isLock && password !== passwordConfirm ;
        return (
            <div>
                <div className="profile-edit">
                    <div className="profile-edit-field">
                        <div className="description-label"><span>{ t('settings.setSubjectLabel') }</span></div>
                        <div className="eb_input">
                            <input type="text" placeholder={ t('settings.setSubjectInput') /*방 이름 입력*/ } value = { subject }  onChange = { ({ target: { value } }) => super._onChange({ subject: value })}/>
                        </div>
                    </div>
                </div>
                <div className="option">
                    <div className="eb_checkbox">
                        <input type="checkbox" id="pw_use" name="usePasswd" checked={ this.props.isLock } onChange={ this._onUseRoomPassword }/>
                        <label htmlFor="pw_use"><span className="labeltxt">{ t('xclick.welcomepage.usePassword') /*비밀번호 사용*/ }</span></label>
                    </div>
                    <div className="option_password" style={this.props.isLock ? {} : {'display':'none'} }>
                        <div className="eb_input">
                            <input type="password" placeholder={ t('xclick.welcomepage.inputPassword') /*비밀번호 입력*/ } value={this.props.password} onChange={ this._onRoomPassword }/>
                        </div>
                        <div className="eb_input">
                            <input type="password" placeholder={ t('xclick.welcomepage.confirmPassword') /*비밀번호 재입력*/ } value={this.props.passwordConfirm} onChange={ this._onRoomPasswordConfirm }/>
                        </div>
                    </div>
                    <div className="note_wrap">
                        {(roomPassword_required ) 
                            && <span className="note">{ t('xclick.welcomepage.noPassword') /*비밀번호를 입력해주세요.*/ }</span>}
                        {(this.props.isLock 
                            && !roomPassword_required 
                            && !roomPasswordConfirm_not_match) 
                            && <span className="note correct">{ t('xclick.welcomepage.correctPassword') /*비밀번호가 일치합니다.*/ }</span>}
                        {(roomPasswordConfirm_not_match ) 
                            && <span className="note wrong">{ t('xclick.welcomepage.wrongPassword') /*비밀번호가 일치하지 않습니다.*/ }</span>}
                    </div>
                </div>
            </div>
        );
    }

    
    _onUseRoomPassword(e) {
        //this.setState({ useRoomPassword : e.target.checked });
        super._onChange({ isLock: e.target.checked});
    }
    
    _onRoomPassword(e) {
        //this.setState({ roomPassword : e.target.value });
        super._onChange({ password: e.target.value});
    }

    _onRoomPasswordConfirm(e){
        //this.setState({roomPasswordConfirm : e.target.value});
        super._onChange({ passwordConfirm: e.target.value});
    }
}

export default translate(RoomTab);
