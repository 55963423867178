const axios = require('axios');

export function ajaxJsonData(param, callbackFn) {
	try {
		axios.get(getContextPath() + ("Y" == param.xctp ? "/AjaxController.do?" : "/AjaxController?"), {
			params: param,
		}).then((rtnData) => {
			if(callbackFn && typeof callbackFn == 'function') {
				callbackFn.call(this, rtnData.data);
			}
		});
	} catch(error) {
		console.log(error);
	};
}

export function findObjectFromArray(array, keyname, key){
	if(array && array != null){
	    for(var i = 0; i < array.length; i++){
	        if(array[i][keyname] === key){
	            return array[i];
	        }
	    }
	}
    return;
}

function getContextPath() {
    var contextPath = window.location.pathname.substring(0, window.location.pathname.indexOf("/",2));
	if(isNullStr(contextPath)){
		contextPath = "";
	}
	
	return contextPath;
}