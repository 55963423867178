/**
 * 조직도 Action Types, 2020.10.28, LeeJx2
 * 
 */
export const OPEN_ORG_LIST = 'OPEN_ORG_LIST';

export const CLOSE_ORG_LIST = 'CLOSE_ORG_LIST';

export const SEARCH_USER = 'SEARCH_USER';

export const SELECT_COMPANY = 'SELECT_COMPANY';

export const NAVIGATE_ORG = 'NAVIGATE_ORG';

export const SEND_INVITE_MESSAGE = 'SEND_INVITE_MESSAGE';
