
export function openOrgPop(roomName, deptId, companyId, inviteUser, inviteUserId) {
	var vTop = Math.max(0, ((screen.height - height) / 2)-100);
	var vLeft = (screen.width - width) / 2;
	var width  = 1100;
	var height = 800;
	var _webapp = location.origin+"/meet";
	
	let sFeatures = 'width='+width+',height='+height+',top='+vTop+',left='+vLeft+',toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no,resizable=no';
	
	var pop = window.open(_webapp + "?deptId=" + deptId + "&companyId=" + companyId + "&roomName=" + roomName + "&inviteUser=" + inviteUser + "&inviteUserId=" + inviteUserId, '', sFeatures);
	pop.focus();
	
	return pop;
}