import { PersistenceRegistry, ReducerRegistry, set } from '../../base/redux';

import { SET_USER_INFO, SET_USER_RECENT_JOIN_ROOM } from './actionTypes';

import { i18next } from '../../base/i18n';

let defaultInfo = undefined;
function _getInitialState() {
    if(defaultInfo){
        return defaultInfo;
    }else if(config.userinfo){
        var buf = new Buffer(config.userinfo, 'base64');
        let defaultInfo = JSON.parse(buf);
        i18next.changeLanguage(defaultInfo.locale);
        
        return defaultInfo;
    }else{
        return {
            userid: "",
            username: "",
            loginid: "",
            companyid: "",
            companyname: "",
            deptid: "",
            deptname: "",
            positionid: "",
            positionname: "",
            functionid: "",
            functionname: "",
            ofclevelid: "",
            ofclevelname: "",
            rollid: "",
            rollname: "",
            reptitlename: "",
            externaluser: false,
            locale: "",
            photofileid: ""
        }
    }
}

ReducerRegistry.register(
    'features/xclick/userinfo',
    (state: Object = _getInitialState(), action: Object) => {
        switch (action.type) {
            case SET_USER_INFO:
				const { userinfo, locale } = action;
                return {
                    ...state,
                    ...userinfo
                };
            case SET_USER_RECENT_JOIN_ROOM : 
                const { recentJoinRoom } = action;
                return{
                    ...state,
                    recentJoinRoom
                }
        }

        return state;
    });