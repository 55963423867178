/* global interfaceConfig */

import React from 'react';

import { isMobileBrowser } from '../../base/environment/utils';
import { translate, translateToHTML, i18next } from '../../base/i18n';
import { Icon, IconWarning } from '../../base/icons';
import { Watermarks } from '../../base/react';
import { connect } from '../../base/redux';
import { CalendarList } from '../../calendar-sync';
import { RecentList } from '../../recent-list';
import { SettingsButton, SETTINGS_TABS } from '../../settings';

import { AbstractWelcomePage, _mapStateToProps } from './AbstractWelcomePage';
import { ajaxJsonData } from '../../xclick/common';
import { loadRecentJoinRoom, deleteRecentJoinRoom } from '../../welcome';
import Tabs from './Tabs';

/**
 * The pattern used to validate room name.
 * @type {string}
 */
export const ROOM_NAME_VALIDATE_PATTERN_STR = '^[^?&:\u0022\u0027%#]+$';

/**
 * Maximum number of pixels corresponding to a mobile layout.
 * @type {number}
 */
const WINDOW_WIDTH_THRESHOLD = 425;

/**
 * The Web container rendering the welcome page.
 *
 * @extends AbstractWelcomePage
 */
class WelcomePage extends AbstractWelcomePage {
    /**
     * Default values for {@code WelcomePage} component's properties.
     *
     * @static
     */
    static defaultProps = {
        _room: ''
    };

    /**
     * Initializes a new WelcomePage instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            generateRoomnames: interfaceConfig.GENERATE_ROOMNAMES_ON_WELCOME_PAGE,
            selectedTab: 0,

            roomPasswordConfirm : '',
        };

        /**
         * The HTML Element used as the container for additional content. Used
         * for directly appending the additional content template to the dom.
         *
         * @private
         * @type {HTMLTemplateElement|null}
         */
        this._additionalContentRef = null;

        this._roomInputRef = null;

        /**
         * The HTML Element used as the container for additional toolbar content. Used
         * for directly appending the additional content template to the dom.
         *
         * @private
         * @type {HTMLTemplateElement|null}
         */
        this._additionalToolbarContentRef = null;

        /**
         * The template to use as the main content for the welcome page. If
         * not found then only the welcome page head will display.
         *
         * @private
         * @type {HTMLTemplateElement|null}
         */
        this._additionalContentTemplate = document.getElementById(
            'welcome-page-additional-content-template');

        /**
         * The template to use as the additional content for the welcome page header toolbar.
         * If not found then only the settings icon will be displayed.
         *
         * @private
         * @type {HTMLTemplateElement|null}
         */
        this._additionalToolbarContentTemplate = document.getElementById(
            'settings-toolbar-additional-content-template'
        );

        // Bind event handlers so they are only bound once per instance.
        this._onFormSubmit = this._onFormSubmit.bind(this);
        this._onRoomChange = this._onRoomChange.bind(this);
        this._setAdditionalContentRef
            = this._setAdditionalContentRef.bind(this);
        this._setRoomInputRef = this._setRoomInputRef.bind(this);
        this._setAdditionalToolbarContentRef
            = this._setAdditionalToolbarContentRef.bind(this);
        this._onTabSelected = this._onTabSelected.bind(this);

        this._onUseRoomPassword = this._onUseRoomPassword.bind(this);
        this._onRoomPassword = this._onRoomPassword.bind(this);
        this._onDisplayName = this._onDisplayName.bind(this);
        this._onDisplayNameSubmit = this._onDisplayNameSubmit.bind(this);
        this._onRoomPasswordConfirm = this._onRoomPasswordConfirm.bind(this);
        this.__onJoinFromRecentRoom = this.__onJoinFromRecentRoom.bind(this);
    }

    /**
     * Implements React's {@link Component#componentDidMount()}. Invoked
     * immediately after this component is mounted.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        super.componentDidMount();

        document.body.classList.add('welcome-page');
        document.title = interfaceConfig.APP_NAME;

        if (this.state.generateRoomnames) {
            this._updateRoomname();
        }

        if (this._shouldShowAdditionalContent()) {
            this._additionalContentRef.appendChild(
                this._additionalContentTemplate.content.cloneNode(true));
        }

        if (this._shouldShowAdditionalToolbarContent()) {
            this._additionalToolbarContentRef.appendChild(
                this._additionalToolbarContentTemplate.content.cloneNode(true)
            );
        }
    }

    /**
     * Removes the classname used for custom styling of the welcome page.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentWillUnmount() {
        super.componentWillUnmount();

        document.body.classList.remove('welcome-page');
    }
    
    _refreshJoinRoomList = () => {
    	loadRecentJoinRoom(this.props.dispatch, this.props._userinfo.loginid, this.props._userinfo.locale);
    }
    
    _deleteRoom = (roomId, name) => {
    	deleteRecentJoinRoom(this.props._userinfo.loginid, roomId, name, this._refreshJoinRoomList);
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement|null}
     */
    render() {
        const { _moderatedRoomServiceUrl, t } = this.props;
        const { APP_NAME, DEFAULT_WELCOME_PAGE_LOGO_URL } = interfaceConfig;
        const showAdditionalContent = this._shouldShowAdditionalContent();
        const showAdditionalToolbarContent = this._shouldShowAdditionalToolbarContent();
        const showResponsiveText = this._shouldShowResponsiveText();
        
        let userPhotoSrc;
        if(this.props._userinfo.externaluser) {
        	userPhotoSrc = "buzzmeet/resource/img/common/img_profile_external.png"
        } else if (!this.props._userinfo.photofileid || '' === this.props._userinfo.photofileid) {
            userPhotoSrc = "buzzmeet/resource/img/common/img_profile_lar.png";
        } else {
            userPhotoSrc = "DownController?fileId=" + this.props._userinfo.photofileid
        }

        const recentJoinRoom = this.props._userinfo.recentJoinRoom || [];
        const roomPassword_required = this.state.useRoomPassword && this.state.roomPassword === "";
        const roomPasswordConfirm_not_match = this.state.useRoomPassword && this.state.roomPassword !== this.state.roomPasswordConfirm;

        return (
            <div className="layout" id="welcome_page">
                <div id="header" className="header">
                    <div className="header_wrap">
                        <div className="header_logo_wrap">
                            <div className="logo">
                                <img src="buzzmeet/resource/img/common/img_logo.png" alt={t('xclick.welcomepage.logo')} />
                            </div>
                        </div>
                        <button type="button" className="eb_btn btn_logout" onClick={ ()=>{ window.logout(); } }>{ t('xclick.welcomepage.logout') /*로그아웃*/ }</button>
                    </div>
                </div>
                <div id="container" className="container">
                    <div className="cont_wrap main">
                        <div className="user_profile">
                            <div className="portrait">
                                <img src = { userPhotoSrc } onError = { this.imgOnError } alt="profile" />
                            </div>

                            <div className="inner" style={this.props._userinfo.externaluser ? {'display':'none'} : {} }>
                                <div className="name">{this.props._userinfo.username}</div>
                                <div className="depart">{this.props._userinfo.reptitlename} / {this.props._userinfo.deptname}</div>
                            </div>
                            <div className="outer" style={this.props._userinfo.externaluser ? {} : {'display':'none'} }>
                                <div className="name">
                                    <div className="eb_input">
                                        <input type="text" name="nickname" value={ this.state.displayName } placeholder="email_address@email.com" onChange={ this._onDisplayName } />
                                    </div>
                                    <button type="button" className="eb_btn" onClick={ this._onDisplayNameSubmit }>{ t('xclick.welcomepage.change') /*변경*/ }</button>
                                </div>
                            </div>
                        </div>

                        {!this.props._userinfo.externaluser && 
                        <div className = { "enter_room"+(this.state.useRoomPassword?" op_pw":"") }>
                            <form onSubmit = { this._onFormSubmit }>
                                <div className="eb_input room_title">
                                    <input
                                        pattern = { ROOM_NAME_VALIDATE_PATTERN_STR }
                                        id='enter_subject_field'
                                        type='text'
                                        autoFocus = { true }
                                        onChange = { this._onRoomChange }
                                        placeholder={ t('xclick.welcomepage.description') /*새 화상회의 만들기*/ } 
                                        ref = { this._setRoomInputRef }
                                        value = { this.state.subject } />
                                    <input
                                        id='enter_room_field' 
                                        type="hidden"
                                        value = { this.state.room } />
                                    { this._renderInsecureRoomNameWarning() }
                                </div>
                                <div className="option">
                                    <div className="eb_checkbox">
                                        <input type="checkbox" id="pw_use" name="usePasswd" defaultValue="true" checked={ this.state.useRoomPassword } onChange={ this._onUseRoomPassword } />
                                        <label htmlFor="pw_use"><span className="labeltxt">{ t('xclick.welcomepage.usePassword') /*비밀번호 사용*/ }</span></label>
                                    </div>
                                    <div className="option_password">
                                        <div className="eb_input" style={this.state.useRoomPassword ? {} : {'display':'none'} }>
                                            <input type="password" placeholder={ t('xclick.welcomepage.inputPassword') /*비밀번호 입력*/ } value={ this.state.roomPassword } onChange={ this._onRoomPassword }/>
                                        </div>
                                        <div className="eb_input" style={this.state.useRoomPassword ? {} : {'display':'none'} }>
                                            <input type="password" placeholder={ t('xclick.welcomepage.confirmPassword') /*비밀번호 재입력*/ } value={ this.state.roomPasswordConfirm } onChange={ this._onRoomPasswordConfirm }/>
                                        </div>
                                        <div className="note">
                                            {(this.state.useRoomPassword 
                                                && !roomPassword_required 
                                                && !roomPasswordConfirm_not_match) 
                                                && <span className="correct">{ t('xclick.welcomepage.correctPassword') /*비밀번호가 일치합니다.*/ }</span>}
                                            {(roomPasswordConfirm_not_match ) 
                                                && <span className="wrong">{ t('xclick.welcomepage.wrongPassword') /*비밀번호가 일치하지 않습니다.*/ }</span>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <button type="button" className="eb_btn btn_confirm" onClick = { this._onFormSubmit } disabled={ roomPassword_required || roomPasswordConfirm_not_match  }>{ t('xclick.welcomepage.enterRoom') /*시작*/ }</button>
                        </div>
                        }
                        <div className="meetings_list">
                            <div className="title_wrap">
                            	{ t('xclick.welcomepage.recentJoinRoom') /*지난 화상회의*/ }
                            	<button type="button" className="eb_btn icon_btn btn_reload" onClick={this._refreshJoinRoomList}></button>
                            </div>
                            <div className="list">
                            {recentJoinRoom.map(roominfo => {
                                return (
                                    <div className="item" key={roominfo.name}
                                    	onClick = { (e) => {
                                    		if(e.target.className && e.target.className.indexOf("btn_del") > -1) {}
                                    		else {
                                    			this.__onJoinFromRecentRoom( roominfo.name )
                                    		}
                                    	}}>
                                        <div className="left_column">
                                            <span className="date">{roominfo.date}</span>
                                            <span>{roominfo.time}</span>
                                        </div>
                                        <div className="right_column">
                                            <span className="title">{roominfo.subject}</span>
                                            <span className="master">{roominfo.managername}</span>
                                        </div>
                                        <button type="button" className="eb_btn btn_del"
                                        	onClick={() => {
                                        		this._deleteRoom(roominfo.roomid, roominfo.name)
                                        	}}
                                        	>
                                        </button>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    imgOnError = () => {
		this.setState({imgSrc : "buzzmeet/resource/img/common/img_profile_lar.png"})
	}

    /**
     * Renders the insecure room name warning.
     *
     * @inheritdoc
     */
    _doRenderInsecureRoomNameWarning() {
        return (
            <div className = 'insecure-room-name-warning'>
                <Icon src = { IconWarning } />
                <span>
                    { this.props.t('security.insecureRoomNameWarning') }
                </span>
            </div>
        );
    }

    /**
     * Prevents submission of the form and delegates join logic.
     *
     * @param {Event} event - The HTML Event which details the form submission.
     * @private
     * @returns {void}
     */
    _onFormSubmit(event) {
    	const { t } = this.props;
    	
    	if(!this.state.subject || '' === this.state.subject) {
        	alert(t('xclick.alert.requiredSubject' /* 방 이름을 입력해주세요! */));
        	
        	return;
        }
    	
        event.preventDefault();
        
        if (!this._roomInputRef || this._roomInputRef.reportValidity()) {
            this._onJoin();
        }
    }

    /**
     * Overrides the super to account for the differences in the argument types
     * provided by HTML and React Native text inputs.
     *
     * @inheritdoc
     * @override
     * @param {Event} event - The (HTML) Event which details the change such as
     * the EventTarget.
     * @protected
     */
    _onRoomChange(event) {
        super._onRoomChange(event.target.value);
    }

    /**
     * Callback invoked when the desired tab to display should be changed.
     *
     * @param {number} tabIndex - The index of the tab within the array of
     * displayed tabs.
     * @private
     * @returns {void}
     */
    _onTabSelected(tabIndex) {
        this.setState({ selectedTab: tabIndex });
    }

    /**
     * Renders tabs to show previous meetings and upcoming calendar events. The
     * tabs are purposefully hidden on mobile browsers.
     *
     * @returns {ReactElement|null}
     */
    _renderTabs() {
        if (isMobileBrowser()) {
            return null;
        }

        const { _calendarEnabled, _recentListEnabled, t } = this.props;

        const tabs = [];

        if (_calendarEnabled) {
            tabs.push({
                label: t('welcomepage.calendar'),
                content: <CalendarList />
            });
        }

        if (_recentListEnabled) {
            tabs.push({
                label: t('welcomepage.recentList'),
                content: <RecentList />
            });
        }

        if (tabs.length === 0) {
            return null;
        }

        return (
            <Tabs
                onSelect = { this._onTabSelected }
                selected = { this.state.selectedTab }
                tabs = { tabs } />);
    }

    /**
     * Sets the internal reference to the HTMLDivElement used to hold the
     * welcome page content.
     *
     * @param {HTMLDivElement} el - The HTMLElement for the div that is the root
     * of the welcome page content.
     * @private
     * @returns {void}
     */
    _setAdditionalContentRef(el) {
        this._additionalContentRef = el;
    }

    /**
     * Sets the internal reference to the HTMLDivElement used to hold the
     * toolbar additional content.
     *
     * @param {HTMLDivElement} el - The HTMLElement for the div that is the root
     * of the additional toolbar content.
     * @private
     * @returns {void}
     */
    _setAdditionalToolbarContentRef(el) {
        this._additionalToolbarContentRef = el;
    }

    /**
     * Sets the internal reference to the HTMLInputElement used to hold the
     * welcome page input room element.
     *
     * @param {HTMLInputElement} el - The HTMLElement for the input of the room name on the welcome page.
     * @private
     * @returns {void}
     */
    _setRoomInputRef(el) {
        this._roomInputRef = el;
    }

    /**
     * Returns whether or not additional content should be displayed below
     * the welcome page's header for entering a room name.
     *
     * @private
     * @returns {boolean}
     */
    _shouldShowAdditionalContent() {
        return interfaceConfig.DISPLAY_WELCOME_PAGE_CONTENT
            && this._additionalContentTemplate
            && this._additionalContentTemplate.content
            && this._additionalContentTemplate.innerHTML.trim();
    }

    /**
     * Returns whether or not additional content should be displayed inside
     * the header toolbar.
     *
     * @private
     * @returns {boolean}
     */
    _shouldShowAdditionalToolbarContent() {
        return interfaceConfig.DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT
            && this._additionalToolbarContentTemplate
            && this._additionalToolbarContentTemplate.content
            && this._additionalToolbarContentTemplate.innerHTML.trim();
    }

    /**
     * Returns whether or not the screen has a size smaller than a custom margin
     * and therefore display different text in the go button.
     *
     * @private
     * @returns {boolean}
     */
    _shouldShowResponsiveText() {
        const { innerWidth } = window;

        return innerWidth <= WINDOW_WIDTH_THRESHOLD;
    }

    _onUseRoomPassword(e) {
        super._onUseRoomPassword(e.target.checked);
    }

    _onRoomPassword(e) {
        super._onRoomPassword(e.target.value);
    }

    _onDisplayName(e) {
        super._onDisplayName(e.target.value);
    }

    _onDisplayNameSubmit(e){
        const displayName = this.state.displayName;
        ajaxJsonData({
            facade: "GateFacade",
            command: "setDisplayNameAjax",
            displayName : displayName
        }, ()=>{} );
    }

    _onRoomPasswordConfirm(e){
        this.setState({roomPasswordConfirm : e.target.value});
    }

    __onJoinFromRecentRoom(roomName){
        super._onJoin(roomName);
    }
}

export default translate(connect(_mapStateToProps)(WelcomePage));
