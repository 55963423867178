// @flow

import { ReducerRegistry, set } from '../../base/redux';

import {
	OPEN_ORG_LIST, 
	CLOSE_ORG_LIST, 
	SEARCH_USER, 
	SELECT_COMPANY, 
	NAVIGATE_ORG, 
	SEND_INVITE_MESSAGE,
	SHOW_SELECTED_BUTTON
} from './actionTypes';

declare var interfaceConfig: Object;

/**
 * Returns initial state for toolbox's part of Redux store.
 *
 * @private
 * @returns {{
 *     orgListVisible
 * }}
 */
function _getInitialState() {
    let orgListVisible = false;

    return {
    	orgListVisible
    };
}

ReducerRegistry.register(
    'features/xclick/org',
    (state: Object = _getInitialState(), action: Object) => {
        switch (action.type) {
        case OPEN_ORG_LIST:
            return {
                ...state,
                orgListVisible: true
            };
        case CLOSE_ORG_LIST:
            return {
                ...state,
                orgListVisible: false
            };
        }
        return state;
    });
