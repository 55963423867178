// @flow

import { setFollowMe, setStartMutedPolicy } from '../base/conference';
import { openDialog } from '../base/dialog';
import { i18next } from '../base/i18n';
import { updateSettings } from '../base/settings';
import { setPrejoinPageVisibility } from '../prejoin/actions';

import {
    SET_AUDIO_SETTINGS_VISIBILITY,
    SET_VIDEO_SETTINGS_VISIBILITY
} from './actionTypes';
import { SettingsDialog } from './components';
import { getMoreTabProps, getProfileTabProps, getRoomTabProps } from './functions';
import AesUtil from '../../../modules/UI/util/AesUtil';
import {setPassword} from '../base/conference';
import { conferenceSubjectChanged } from '../base/conference';
declare var APP: Object;

/**
 * Opens {@code SettingsDialog}.
 *
 * @param {string} defaultTab - The tab in {@code SettingsDialog} that should be
 * displayed initially.
 * @returns {Function}
 */
export function openSettingsDialog(defaultTab: string) {
    return openDialog(SettingsDialog, { defaultTab });
}

/**
 * Sets the visiblity of the audio settings.
 *
 * @param {boolean} value - The new value.
 * @returns {Function}
 */
function setAudioSettingsVisibility(value: boolean) {
    return {
        type: SET_AUDIO_SETTINGS_VISIBILITY,
        value
    };
}

function updateRoomPassword(roomName, password, currentState){
    ajaxJsonData({
        facade: "RoomFacade",
        command: "updateRoomPasswordAjax",
        xctp: "Y",
        roomName: roomName,
        password: password
    }, function(data){
        if(data.result == "OK"){
            APP.store.dispatch(setPassword(currentState.conference, currentState.conference.lock, password));    
        }
    });  
}

function updateRoomSubject(roomName, subject){
    ajaxJsonData({
        facade: "RoomFacade",
        command: "updateSubjectAjax",
        xctp: "Y",
        roomName: roomName,
        subject: subject
    }, function(data){
        if(data.result == "OK"){
            APP.store.dispatch(conferenceSubjectChanged(subject)); 
        }
    });  
}


/**
 * Sets the visiblity of the video settings.
 *
 * @param {boolean} value - The new value.
 * @returns {Function}
 */
function setVideoSettingsVisibility(value: boolean) {
    return {
        type: SET_VIDEO_SETTINGS_VISIBILITY,
        value
    };
}

/**
 * Submits the settings from the "More" tab of the settings dialog.
 *
 * @param {Object} newState - The new settings.
 * @returns {Function}
 */
export function submitMoreTab(newState: Object): Function {
    return (dispatch, getState) => {
        const currentState = getMoreTabProps(getState());

        if (newState.followMeEnabled !== currentState.followMeEnabled) {
            dispatch(setFollowMe(newState.followMeEnabled));
        }

        const showPrejoinPage = newState.showPrejoinPage;

        if (showPrejoinPage !== currentState.showPrejoinPage) {
            // The 'showPrejoin' flag starts as 'true' on every new session.
            // This prevents displaying the prejoin page when the user re-enables it.
            if (showPrejoinPage && getState()['features/prejoin']?.showPrejoin) {
                dispatch(setPrejoinPageVisibility(false));
            }
            dispatch(updateSettings({
                userSelectedSkipPrejoin: !showPrejoinPage
            }));
        }

        if (newState.startAudioMuted !== currentState.startAudioMuted
            || newState.startVideoMuted !== currentState.startVideoMuted) {
            dispatch(setStartMutedPolicy(
                newState.startAudioMuted, newState.startVideoMuted));
        }

        if (newState.currentLanguage !== currentState.currentLanguage) {
            i18next.changeLanguage(newState.currentLanguage);
        }
    };
}

/**
 * Submits the settings from the "Profile" tab of the settings dialog.
 *
 * @param {Object} newState - The new settings.
 * @returns {Function}
 */
export function submitProfileTab(newState: Object): Function {
    return (dispatch, getState) => {
        const currentState = getProfileTabProps(getState());

        if (newState.displayName !== currentState.displayName) {
            APP.conference.changeLocalDisplayName(newState.displayName);

            //Bizwell.
            //Session의 dispalyName을 변경한다.
            ajaxJsonData({
                facade: "GateFacade",
                command: "setDisplayNameAjax",
                displayName : newState.displayName
            }, ()=>{} );
        }

        if (newState.email !== currentState.email) {
            APP.conference.changeLocalEmail(newState.email);
        }
    };
}

export function submitRoomTab(newState: Object): Function {
    return (dispatch, getState) => {
        const currentState = getRoomTabProps(getState());

        var roomInfo = JSON.parse(config.roominfo);
        roomInfo.roomStatus = true; //closeDialog 플래그
        config.roominfo = JSON.stringify(roomInfo);
        
        let encryptPasswd = "";

        if(newState.isLock){
            let localPassword = APP.store.getState()['features/base/conference'].password;
            if(localPassword !="" && localPassword == newState.password){ //변경되지 않음
                
            }else if( (newState.passwordConfirm != "" && newState.password != "") && (newState.password == newState.passwordConfirm) ){
                encryptPasswd = AesUtil.encrypt("_xMeet_key", newState.password);
                updateRoomPassword(currentState.room, encryptPasswd, currentState);              
            }else{
                alert(i18next.t('xclick.alert.confirmPasswd'));
                roomInfo.roomStatus = false;
                config.roominfo = JSON.stringify(roomInfo);
            }
        }else{
            if(currentState.isLock != newState.isLock){
                updateRoomPassword(currentState.room, encryptPasswd, currentState);
            }
        }

        if (newState.subject !== currentState.subject) {
            if(newState.subject == ""){
                alert(i18next.t('xclick.alert.requiredSubject'));
                roomInfo.roomStatus = false;
                config.roominfo = JSON.stringify(roomInfo);
            }else{
                updateRoomSubject(currentState.room, newState.subject)
            }

        }
        
    };
}



/**
 * Toggles the visiblity of the audio settings.
 *
 * @returns {void}
 */
export function toggleAudioSettings() {
    return (dispatch: Function, getState: Function) => {
        const value = getState()['features/settings'].audioSettingsVisible;

        dispatch(setAudioSettingsVisibility(!value));
    };
}

/**
 * Toggles the visiblity of the video settings.
 *
 * @returns {void}
 */
export function toggleVideoSettings() {
    return (dispatch: Function, getState: Function) => {
        const value = getState()['features/settings'].videoSettingsVisible;

        dispatch(setVideoSettingsVisibility(!value));
    };
}
