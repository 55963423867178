// @flow

import React from 'react';

import { translate } from '../../../base/i18n';
import { Icon, IconCancelSelection } from '../../../base/icons';
import { connect } from '../../../base/redux';
import AbstractMessageRecipient, {
    _mapDispatchToProps,
    _mapStateToProps,
    type Props
} from '../AbstractMessageRecipient';

/**
 * Class to implement the displaying of the recipient of the next message.
 */
class MessageRecipient extends AbstractMessageRecipient<Props> {
    /**
     * Implements {@code PureComponent#render}.
     *
     * @inheritdoc
     */
    render() {
        const { _privateMessageRecipient } = this.props;

        if (!_privateMessageRecipient) {
            return null;
        }

        const { t } = this.props;

        const msg = 'Remote' === _privateMessageRecipient ? t('chat.toEmpty') : t('chat.messageTo', { recipient: _privateMessageRecipient });

        return (
            //Bizwell.
            <div className = 'chat_recipient'>
                <span>
                    { msg }
                </span>
                <button type="button" 
                        className="eb_btn icon_btn btn_close" 
                        onClick = { this.props._onRemovePrivateMessageRecipient }>
                </button>
            </div>
        );
    }
}

export default translate(connect(_mapStateToProps, _mapDispatchToProps)(MessageRecipient));
