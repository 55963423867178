import React, { Component } from "react";
import { connect } from '../../../../base/redux';
import { closeOrgList, showSelectedBtn } from '../../actions';
import type { Dispatch } from 'redux';

type Props = {
	_isVisible: boolean,
	
	_closeModal: Function,
}

type State = {
	orgListVisible: boolean,
	
	selectedOption: Object,
	
	isShowingOptions: boolean,
	
	showInviteBtn: boolean,
}

const items = {
	defaultValue: { value: 'COM0', label: '비즈웰'},
	options: [],
	animationType: 'none',
}

class OrgList extends Component<P,S> {
	defaultState = {
		orgListVisible: false,
		selectedOption: items.defaultValue,
		isShowingOptions: false,
		eventCheckBoxIsOn: false,
		showInviteBtn: false,
	}
	
	constructor(props: P) {
		super(props);
		
		this.state = this.defaultState;
	}
	
	_onShow(value: boolean): void {
	    this.setState({
	    	isShowingOptions: value,
	    });
	}
	_onSelect(item: Object, isShow: boolean): void {
	    this.setState({
	    	isShowingOptions: isShow,
	    	selectedOption: item,
	    });
	}
	_toggleInviteBtn(value: boolean): void {
		this.setState({
			showInviteBtn: value,
		});
	}
	
	/**
     * Implements {@code Component#componentDidUpdate}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        if (prevProps._isVisible !== this.props._isVisible) {
            // Clear state
            this._clearState();
        }
    }
    
    /**
     * Clears the dialog content.
     *
     * @returns {void}
     */
    _clearState() {
        this.setState(this.defaultState);
    }
    
    render() {
    	let {isShowingOptions, selectedOption} = this.state;
    	return (
		    <div></div>
    	);
    }
}

export function _mapStateToProps(state: Object) {
    return {
        _isVisible: state['features/xclick/org'].orgListVisible,
    };
}
export function _mapDispatchToProps(dispatch: Dispatch<any>) {
	return {
		_closeModal() {
			dispatch(closeOrgList());
		}
	}
}

export default connect(_mapStateToProps, _mapDispatchToProps)(OrgList);
