import { ajaxJsonData } from '../common';
import { browser } from '../../base/lib-jitsi-meet';

export function getUserSortList(callbackFn, roomName) {
	let returnData = [];
	let xctp = 'Y';
	
	if(!browser.isReactNative()) {
		let uri = location.href;
		let idx = uri.lastIndexOf('/');
		roomName = uri.substring(idx+1, uri.length);
		xctp = 'N';
	}
	
	ajaxJsonData({
		facade: 'RoomFacade',
		command: 'listCurrentRoomUserAjax',
		roomName: roomName,
		xctp: xctp,
	}, callbackFn);
}