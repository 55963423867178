import type { Component } from 'react';
import { MiddlewareRegistry } from '../../base/redux';

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
    case 'LOGINAFTER':
    	store.getState()['features/xclick/login'].userData = action.userInfo;
    }
    return next(action);
});

