import React, { Component } from "react";
import { connect } from '../../../../base/redux';
import type { Dispatch } from 'redux';

import { beginAddPeople } from '../../../../invite';
import { JitsiParticipantConnectionStatus } from '../../../../base/lib-jitsi-meet';
import { getUserSortList } from '../../functions';
import { translate } from '../../../../base/i18n';

type Props = {
	_isVisible: boolean,
	
	_roomURL: string,
	
	_userMap : Map,
	
	_joinedUserList: Array,
	
	_inviteUserList: Array,
	
	_currUserCnt: number,
	
	_totalUserCnt: number,

	t: Function
}

type State = {
	modalVisible: boolean,
	
	joinedUserList: Array,
	
	inviteUserList: Array,
	
	userMap: Map,
	
	sortList: Array,
}

const modalStyles = {
	position: 'absolute',
	top: '100px',
	left: '50%',
	width: '360px',
	marginLeft: '-180px',
	zIndex: '1000',
}

const unsetStyles = {
	display: 'none',
}

class Participants extends Component<P,S> {
	defaultState = {
		modalVisible: false,
		userList: [],
	}
	
	constructor(props: P) {
		super(props);
		
		this.state = this.defaultState;
		this._invitePeople = this._invitePeople.bind(this);
	}
	
	componentDidMount() {
		const sortList = [];
		getUserSortList(this._setSortList);
	}
	
	componentDidUpdate(prevProps) {
		if(!prevProps._isVisible && this.props._isVisible) {
			document.addEventListener('click', this._onClickBackground);
		}else if(prevProps._isVisible && !this.props._isVisible) {
			document.removeEventListener('click', this._onClickBackground);
		}
	}
	
	_onClickBackground = (e) => {
		if(e.target.id && e.target.id != 'participantDIV' && e.target.id.indexOf('icon_toolbar_user') == -1) {
			this._closeModal();
		}
	}
	
	_setSortList = (rtnData) => {
		this.setState({sortList: rtnData});
	}
	
	_invitePeople() {
		this._closeModal();
		this.props.dispatch(beginAddPeople());
	}
	
	_closeModal = () => {
		this.props.dispatch({type: 'SHOW_PARTICIPANT_LIST'});
	}
	
	render() {
		const { _userMap, t } = this.props;
		const itemRender = (element) => {
			const audioUsing = _userMap.get(element.id) && _userMap.get(element.id).audioUsing ? "active" : "";
			const videoUsing = _userMap.get(element.id) && _userMap.get(element.id).videoUsing ? "active" : "";
			
			const isManager = _userMap.get(element.id) && _userMap.get(element.id).isAdmin;
			const isJoined = element.isJoined;
			
			let className = "";
			if(isManager) {
				className += "master";
			}
			if(!isJoined) {
				className += " inviting";
			}
			
			return (
				<li key={element.id} className={className}>
					<div className="name">{element.displayname}</div>
					{ isJoined &&
						<div className="status">
							<button type="button" className={"eb_btn icon_btn btn_setting icon_setting_audio "+audioUsing} />
							<button type="button" className={"eb_btn icon_btn btn_setting icon_setting_video "+videoUsing} />
						</div>
					}
					{ !isJoined &&
						<div className="status">
							<span className="text">{ t('xclick.participant.inviting') /*초대중*/ }</span>
						</div>
					}
				</li>
			)
		};
		const joinedUserList = this.props._joinedUserList
							&& this.props._joinedUserList.length > 0 
							&& this.props._joinedUserList.map(itemRender);
		const inviteUserList = this.props._inviteUserList
							&& this.props._inviteUserList.length > 0
							&& this.props._inviteUserList.map(itemRender);
		return (
			<div style={this.props._isVisible ? modalStyles : unsetStyles} id="participantDIV">
				<div className="popup_wrap">		
					<div className="popup_header">
						<div className="popup_title">{ t('toolbar.participants') /*참여자*/ + ' (' + this.props._currUserCnt + '/' + this.props._totalUserCnt + ')' }</div>
						<div className="popup_close">
							<button
								type="button"
									className="eb_btn icon_btn icon_cross_bk"
								onClick={this._closeModal}
							></button>
						</div>
					</div>
					<div className="popup_body">
						<div className="user_list">
							<ul>
								{ joinedUserList }
								{ inviteUserList }
							</ul>
						</div>
					</div>
					{!this.props._isExternalUser && <div className="popup_footer">
						<div className="btn_wrap">
							<button
								type="button"
								className="eb_btn btn_base btn_confirm"
								onClick={this._invitePeople}>{ t('xclick.participant.invite') /*초대하기*/ }</button>
						</div>
					</div> }
				</div>
			</div>
		)
	}
}

export function _mapStateToProps(state: Object) {
	const joinedUserList = state['features/xclick/participant'].joinedUserList || [];
	const inviteUserList = state['features/xclick/participant'].inviteUserList || [];
	
    return {
        _isVisible: state['features/xclick/participant'].modalVisible,
        
        _roomURL: location.href,
        
		_userMap: state['features/xclick/participant'].userMap,
		
		_isExternalUser: state['features/xclick/userinfo'].externaluser,
		
		_joinedUserList: joinedUserList,
        
        _inviteUserList: inviteUserList,
        
        _currUserCnt: joinedUserList.length,
        
        _totalUserCnt: joinedUserList.length + inviteUserList.length
    };
}

export default translate(connect(_mapStateToProps)(Participants));
