// @flow


import { MiddlewareRegistry } from '../../base/redux';
import { i18next } from '../../base/i18n';

/**
 * Middleware that captures room URL sharing actions and starts the sharing
 * process.
 *
 * @param {Store} store - Redux store.
 * @returns {Function}
 */
MiddlewareRegistry.register(store => next => action => {
	switch(action.type) {
	case 'CHANGE_LOCALE':
		changeLocale(action.locale);
		break;
	case 'EXTERNAL_LOGIN':
		externalLogin(store);
		break;
	}
    return next(action);
});

function changeLocale(locale) {
	i18next.changeLanguage(locale);
}

function externalLogin(store) {
	const roomName = store.getState()['features/base/conference'].room;
	APP.conference.init(roomName);
	APP.conference.roomName = roomName;
}
