// @flow

import React, { Component } from 'react';

import { Icon } from '../../icons';
import { AudioSettingsPopup, toggleAudioSettings } from '../../../settings';

type Props = {

    /**
     * The decorated component (ToolboxButton).
     */
    children: React$Node,

    /**
     * Icon of the button.
     */
    icon: Function,

    /**
     * Flag used for disabling the small icon.
     */
    iconDisabled: boolean,

    /**
     * Click handler for the small icon.
     */
    onIconClick: Function,

    /**
     * Additional styles.
     */
    styles?: Object,
};

type State = {

    /**
     * Whether the button is hovered or not.
     */
    isHovered: boolean,
};

/**
 * Displayes the `ToolboxButtonWithIcon` component.
 *
 * @returns {ReactElement}
 */
export default class ToolboxButtonWithIcon extends Component<Props, State> {

    /**
     * Initializes a new {@code ToolboxButtonWithIcon} instance.
     *
     * @param {Props} props - The props of the component.
     */
    constructor(props: Props) {
        super(props);

        this.state = {
            isHovered: false
        };
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
    }

    _onMouseEnter: () => void;

    /**
     * Handler for when the small button has the mouse over.
     *
     * @returns {void}.
     */
    _onMouseEnter() {
        this.setState({
            isHovered: true
        });
    }

    _onMouseLeave: () => void;

    /**
     * Handler for when the mouse leaves the small button.
     *
     * @returns {void}
     */
    _onMouseLeave() {
        this.setState({
            isHovered: false
        });
    }

    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {React$Node}
     */
    render() {
        const {
            children,
            iconDisabled,
            onIconClick,
            buttonType,
            isOpen
        } = this.props;

        const iconProps = {};
        let size = 9;

        let settingBtnClassName = 'eb_btn icon_config';
        if(!iconDisabled){
            iconProps.onClick = onIconClick;
            settingBtnClassName += ' active';
        }

        return (
            <div className="media_setting">
                {children} 
                <button type="button" className={ settingBtnClassName } onClick={ iconProps.onClick }></button>
                {buttonType == 'audio' && !iconDisabled && isOpen && <AudioSettingsPopup/>}
            </div>
        );
    }
}
