let keySize = 4;
let iterations = 100; 
let iterationCount = 100;
let iv = "F27D5C9927726BCEFE7510B1BDD3D137";
let salt = "3FF2EC019C627B945225DEBAD71A01B6985FE84C95A70EB132882F88C0A59A55";

var CryptoJS = require("crypto-js");

const AesUtil = {
	generateKey(salt, passPhrase) {
    const key = CryptoJS.PBKDF2(
      passPhrase, 
      CryptoJS.enc.Hex.parse(salt),
      { keySize: keySize, iterations: iterationCount });
	  return key;
	},

	encrypt(passPhrase, plainText) {
	  const key = this.generateKey(salt, passPhrase);
	  const encrypted = CryptoJS.AES.encrypt(
		  plainText,
		  key,
		  { iv: CryptoJS.enc.Hex.parse(iv) });
	  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
	},

	decrypt(passPhrase, cipherText) {
	  const key = this.generateKey(salt, passPhrase);
	  const cipherParams = CryptoJS.lib.CipherParams.create({
		ciphertext: CryptoJS.enc.Base64.parse(cipherText)
	  });
	  const decrypted = CryptoJS.AES.decrypt(
		  cipherParams,
		  key,
		  { iv: CryptoJS.enc.Hex.parse(iv) });
	  return decrypted.toString(CryptoJS.enc.Utf8);
	}
};
export default AesUtil;