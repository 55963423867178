// @flow

import React from 'react';
import { toArray } from 'react-emoji-render';


import { translate } from '../../../base/i18n';
import { Linkify } from '../../../base/react';
import { MESSAGE_TYPE_LOCAL } from '../../constants';
import AbstractChatMessage, {
    type Props
} from '../AbstractChatMessage';
import PrivateMessageButton from '../PrivateMessageButton';

/**
 * Renders a single chat message.
 */
class ChatMessage extends AbstractChatMessage<Props> {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { message } = this.props;
        const processedMessage = [];

        // content is an array of text and emoji components
        const content = toArray(this._getMessageText(), { className: 'smiley' });

        content.forEach(i => {
            if (typeof i === 'string') {
                processedMessage.push(<Linkify key = { i }>{ i }</Linkify>);
            } else {
                processedMessage.push(i);
            }
        });

        return (
            <li className = { `${message.privateMessage ? 'private' : ''}` } >
                { this.props.showDisplayName && this._renderDisplayName() }
                <div className = 'message_box'>
                    <p className = 'message'>
                        { message.privateMessage && this._renderPrivateNotice() }
                        { processedMessage }
                        { message.privateMessage && message.messageType !== MESSAGE_TYPE_LOCAL
                        && <div>
                                <PrivateMessageButton
                                    _className = 'btn_reply'
                                    participantID = { message.id }
                                    reply = { true }
                                    showLabel = { false } />
                            </div> }
                    </p>
                    { this.props.showTimestamp && this._renderTimestamp() }
                </div>
            </li>
        );
    }

    _getFormattedTimestamp: () => string;

    _getMessageText: () => string;

    _getPrivateNoticeMessage: () => string;

    /**
     * Renders the display name of the sender.
     *
     * @returns {React$Element<*>}
     */
    _renderDisplayName() {
        return (
            <div className = 'name_box'>
                { this.props.message.displayName }
            </div>
        );
    }

    /**
     * Renders the message privacy notice.
     *
     * @returns {React$Element<*>}
     */
    _renderPrivateNotice() {
        return (
            <span className = 'note'>
                { this._getPrivateNoticeMessage() }
            </span>
        );
    }

    /**
     * Renders the time at which the message was sent.
     *
     * @returns {React$Element<*>}
     */
    _renderTimestamp() {
        return (
            <div className = 'send_time_group'> 
                <span className = 'send_time'>
                    { this._getFormattedTimestamp() }
                </span>
            </div>
        );
    }
}

export default translate(ChatMessage);
