import type { Component } from 'react';
import { MiddlewareRegistry } from '../../base/redux';
import {
	isLocalTrackMuted,
	isLocalVideoTrackMuted,
	isRemoteTrackMuted
} from '../../base/tracks';
import { MEDIA_TYPE } from '../../base/media';
import { getUserSortList } from './functions';

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
    case 'SHOW_PARTICIPANT_LIST':
    	showParticipantList(store, action.sortList);
    	break;
    case 'REFRESH_PARTICIPANT_LIST':
    	refreshParticipantList(store);
    	break;
    case 'TRACK_UPDATED':
    	refreshStatus(store, action.track);
    }
    return next(action);
});

function showParticipantList(store, sortList) {
	let state = store.getState();
	
	state['features/xclick/participant'].modalVisible = !state['features/xclick/participant'].modalVisible;
	
	if(sortList) {
		state['features/xclick/participant'].sortList = sortList;
	}
	
	refreshStatus(store);
}

function refreshParticipantList(store) {
	let state = store.getState();
	const roomName = state['features/base/conference'].room;
	
	const setSortList = (rtnData) => {
		state['features/xclick/participant'].sortList = rtnData;
		
		refreshStatus(store, null, rtnData);
	};
	
	getUserSortList(setSortList, roomName);
}

function refreshStatus(store, track) {
	let state = store.getState();
	
	let joinedUserList = [];
	let inviteUserList = [];
	let userMap = state['features/xclick/participant'].userMap;
	const userList = state['features/xclick/participant'].sortList;
	const tracks = state['features/base/tracks'];
	const partList = state['features/base/participants'];
	const myLoginId = state['features/xclick/userinfo'].loginid;
	
	//Prejoin에서 userList가 없으면 갱신하지 않음
	if(userList.length == 0) {
		return;
	}
	
	userList.map((element) => {
		let sessionMap = [];
		partList.map((part) => {
			const partcipantId = part.id.split("$")[0];
			if(element.id === partcipantId) {
				if(element.externalyn === 'Y') {
					element.username = part.name;
				}
				
				sessionMap.push({id: part.id, item: part});
			}
		});
		
		if(element.externalyn === 'Y') {
			element.displayname = element.username.replace("^|^", "@");
		}else {
			element.displayname = element.username + ' ' + element.positionname + ' / ' + element.deptname;
		}
		
		if(sessionMap.length > 0) {
			const isMultiConnect = sessionMap.length > 1;
			sessionMap.map(({id, item}) => {
				let audioUsing = false;
				let videoUsing = false;
				let newElement = JSON.parse(JSON.stringify(element));

				let stats = userMap.get(id);

				if(!track || !stats || track.jitsiTrack.ownerEndpointId != id) {
					if(item.local) {
						audioUsing = !isLocalTrackMuted(tracks, MEDIA_TYPE.AUDIO);
						videoUsing = !isLocalVideoTrackMuted(tracks);
					}else {
						audioUsing = !isRemoteTrackMuted(tracks, MEDIA_TYPE.AUDIO, id);
						videoUsing = !isRemoteTrackMuted(tracks, MEDIA_TYPE.VIDEO, id);
					}
				}else {
					audioUsing = stats.audioUsing;
					videoUsing = stats.videoUsing;
					if(track.jitsiTrack.type == 'audio') {
						audioUsing = !track.muted;
					}else if(track.jitsiTrack.type == 'video') {
						videoUsing = !track.muted;
					}
				}

				userMap.set(id, {audioUsing: audioUsing, videoUsing: videoUsing, isAdmin: element.adminyn == 'Y'});
				newElement.isJoined = true;
				newElement.id = id;
				
				if(isMultiConnect) {
					newElement.displayname += " " + id.split("$")[1];
				}
				
				joinedUserList.push(newElement);
			});
		}else {
			userMap.set(element.id, {audioUsing: false, videoUsing: false, isAdmin: element.adminyn == 'Y'});
			element.isJoined = false;
			inviteUserList.push(element);
		}
	});
	
	state['features/xclick/participant'].joinedUserList = joinedUserList;
	state['features/xclick/participant'].inviteUserList = inviteUserList;
	state['features/xclick/participant'].userMap = userMap;
}
