// @flow
import { getLocalizedDateFormatter } from '../../base/i18n';
import { PureComponent } from 'react';

/**
 * Formatter string to display the message timestamp.
 */
const TIMESTAMP_FORMAT = 'H:mm';

export type Props = {

    /**
     * The messages array to render.
     */
    messages: Array<Object>
}

/**
 * Abstract component to display a list of chat messages, grouped by sender.
 *
 * @extends PureComponent
 */
export default class AbstractMessageContainer<P: Props> extends PureComponent<P> {
    static defaultProps = {
        messages: []
    };

    /**
     * Iterates over all the messages and creates nested arrays which hold
     * consecutive messages sent by the same participant.
     *
     * @private
     * @returns {Array<Array<Object>>}
     */
    _getMessagesGroupedBySender() {
        const messagesCount = this.props.messages.length;
        const groups = [];
        let currentGrouping = [];
        let currentGroupParticipantId;

        for (let i = 0; i < messagesCount; i++) {
            const message = this.props.messages[i];
            let isShowTimestamp = false;
            
            if ( i == ( messagesCount - 1 ) ) {
                isShowTimestamp = true;
            } else {
                let curTime = getLocalizedDateFormatter(new Date(this.props.messages[i].timestamp)).format(TIMESTAMP_FORMAT);
                let afterTime = getLocalizedDateFormatter(new Date(this.props.messages[i+1].timestamp)).format(TIMESTAMP_FORMAT);
                const messages = this.props.messages;
                let curID = messages[i].id ? messages[i].id.split("$")[0] : '';
                let afterID = messages[i+1].id ? messages[i+1].id.split("$")[0] : '';

                if ( curTime != afterTime ) {
                    isShowTimestamp = true;
                } else if ( curID != afterID ) {
                    isShowTimestamp = true;
                }
            }
            message.isShowTimestamp = isShowTimestamp;

            if (message.id === currentGroupParticipantId) {
                currentGrouping.push(message);
            } else {
                currentGrouping.length && groups.push(currentGrouping);

                currentGrouping = [ message ];
                currentGroupParticipantId = message.id;
            }
        }

        currentGrouping.length && groups.push(currentGrouping);

        return groups;
    }
}
