// @flow

import React, { Component } from 'react';
import { MESSAGE_TYPE_ERROR, MESSAGE_TYPE_LOCAL, MESSAGE_TYPE_REMOTE } from '../../constants';
import ChatMessage from './ChatMessage';

type Props = {

    /**
     * Additional CSS classes to apply to the root element.
     */
    className: string,

    /**
     * The messages to display as a group.
     */
    messages: Array<Object>,
};

/**
 * Displays a list of chat messages. Will show only the display name for the
 * first chat message and the timestamp for the last chat message.
 *
 * @extends React.Component
 */
class ChatMessageGroup extends Component<Props> {
    static defaultProps = {
        className: ''
    };
    
    constructor(props) {
		super(props);
		
		const baseUrl = this._getBaseUrl();
		const participantId = this.props.messages[0].id;
		
		this.state = {
			imgSrc : participantId.indexOf("^|^") > -1 ? "buzzmeet/resource/img/common/img_profile_external.png" : baseUrl + '/XMeetFileController?loginid=' + participantId.split('$')[0]
		}
	}

    imgOnError = () => {
		this.setState({imgSrc : "buzzmeet/resource/img/common/img_profile_lar.png"})
	}
    
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     */
    render() {
        const { className, messages } = this.props;

        const messagesLength = messages.length;

        if (!messagesLength) {
            return null;
        }

        return (
            <div className = { `chat_group ${className}` }>
                { this.props.className == MESSAGE_TYPE_REMOTE && this._renderProfilePhoto(messages[0].id) }
                <div className = 'message_wrap'>
                    <ul>
                        {
                            messages.map((message, i) => (
                                <ChatMessage
                                    key = { i }
                                    message = { message }
                                    showDisplayName = { i === 0 && MESSAGE_TYPE_LOCAL !== className }
                                    showTimestamp = { message.isShowTimestamp } />
                            ))
                        }
                    </ul>
                </div>            
            </div>
        );
    }

    _renderProfilePhoto(participantId) {
        return (
            <div className = 'profile_photo'>
                <img src={this.state.imgSrc} onError = { this.imgOnError }/>
            </div>
        )
    }
    
    _getBaseUrl() {
    	let uri = location.href;
    	let idx = uri.lastIndexOf('/');
    	return uri.substring(0, idx);
    }
}

export default ChatMessageGroup;
