// @flow

import { ReducerRegistry, set } from '../../base/redux';

declare var interfaceConfig: Object;

/**
 * Returns initial state for toolbox's part of Redux store.
 *
 * @private
 * @returns {{
 *     orgListVisible
 * }}
 */
function _getInitialState() {
    let modalVisible = false;
    let userList = [];
    let sortList = [];
    let userMap = new Map();
    let adminId = '';

    return {
    	modalVisible,
    	userList,
    	userMap,
    	sortList,
    	adminId,
    };
}

ReducerRegistry.register(
    'features/xclick/participant',
    (state: Object = _getInitialState(), action: Object) => {
        switch (action.type) {
        case 'SHOW_PARTICIPANT_LIST':
            return {
                ...state,
            };
        case 'SET_ADMIN_ID':
        	const { adminId } = action;
        	return {
        		...state,
        		adminId
        	};
        }
        return state;
    });
