// @flow

import { ReducerRegistry, set } from '../../base/redux';

declare var interfaceConfig: Object;

/**
 * Returns initial state for toolbox's part of Redux store.
 *
 * @private
 * @returns {{
 *     orgListVisible
 * }}
 */
function _getInitialState() {
    let id = '';
    let password = '';
    let userData = '';
    let loginId = '';

    return {
    	id, password, userData, loginId
    };
}

ReducerRegistry.register(
    'features/xclick/login',
    (state: Object = _getInitialState(), action: Object) => {
        switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
            };
        }
        return state;
    });
