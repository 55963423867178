/* @flow */

import Tooltip from '@atlaskit/tooltip';
import React from 'react';

import { Icon } from '../../../base/icons';
import AbstractToolbarButton from '../AbstractToolbarButton';
import type { Props as AbstractToolbarButtonProps }
    from '../AbstractToolbarButton';

/**
 * The type of the React {@code Component} props of {@link ToolbarButton}.
 */
type Props = AbstractToolbarButtonProps & {

    /**
     * The text to display in the tooltip.
     */
    tooltip: string,

    /**
     * From which direction the tooltip should appear, relative to the
     * button.
     */
    tooltipPosition: string,

    //Bizwell.
    className: string
};

/**
 * Represents a button in the toolbar.
 *
 * @extends AbstractToolbarButton
 */
class ToolbarButton extends AbstractToolbarButton<Props> {
    /**
     * Default values for {@code ToolbarButton} component's properties.
     *
     * @static
     */
    static defaultProps = {
        tooltipPosition: 'top'
    };

    /**
     * Initializes a new {@code ToolbarButton} instance.
     *
     * @inheritdoc
     */
    constructor(props: Props) {
        super(props);

        this._onKeyDown = this._onKeyDown.bind(this);

        //Bizwell.
        this.state = {
            tooltipVisible: false
        }
        this._renderButton = this._renderButton.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
    }

    _onKeyDown: (Object) => void;

    /**
     * Handles 'Enter' key on the button to trigger onClick for accessibility.
     * We should be handling Space onKeyUp but it conflicts with PTT.
     *
     * @param {Object} event - The key event.
     * @private
     * @returns {void}
     */
    _onKeyDown(event) {
        // If the event coming to the dialog has been subject to preventDefault
        // we don't handle it here.
        if (event.defaultPrevented) {
            return;
        }

        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.props.onClick();
        }
    }

    /**
     * Renders the button of this {@code ToolbarButton}.
     *
     * @param {Object} children - The children, if any, to be rendered inside
     * the button. Presumably, contains the icon of this {@code ToolbarButton}.
     * @protected
     * @returns {ReactElement} The button of this {@code ToolbarButton}.
     */
    _renderButton(children) {
        //Bizwell.
        // return (
        //     <div
        //         aria-label = { this.props.accessibilityLabel }
        //         aria-pressed = { this.props.toggled }
        //         className = 'toolbox-button'
        //         onClick = { this.props.onClick }
        //         onKeyDown = { this._onKeyDown }
        //         role = 'button'
        //         tabIndex = { 0 }>
        //         { this.props.tooltip
        //             ? <Tooltip
        //                 content = { this.props.tooltip }
        //                 position = { this.props.tooltipPosition }>
        //                 { children }
        //             </Tooltip>
        //             : children }
        //     </div>
        // );

        return (
            <button
                className = {`eb_btn icon_btn btn_toolbar ${this.props.className}`}
                onClick = { this.props.onClick }
                onKeyDown = { this._onKeyDown }
                onMouseOver = { this._onMouseOver }
                onMouseOut = { this._onMouseOut }
                tabIndex = { 0 }
            	id = { this.props.className }>
                <div className = {`tooltip_conts ${this.props.tooltipPosition} ${this.state.tooltipVisible ? 'active':''}`}>
                    { this.props.tooltip }
                </div>
            </button>
        );
    }

    /**
     * Renders the icon of this {@code ToolbarButton}.
     *
     * @inheritdoc
     */
    _renderIcon() {
        return (
            <div className = { `toolbox-icon ${this.props.toggled ? 'toggled' : ''}` }>
                <Icon src = { this.props.icon } />
            </div>
        );
    }

    _onMouseOut() {
        this.setState({
            tooltipVisible: false
        })
    }

    _onMouseOver() {
        this.setState({
            tooltipVisible: true
        })
    }
}

export default ToolbarButton;
