/* @flow */
/**
 * 조직도 Actions, 2020.10.28, LeeJx2
 * 
 */

import type { Dispatch } from 'redux';

import { OPEN_ORG_LIST, CLOSE_ORG_LIST, SEARCH_USER, SELECT_COMPANY, NAVIGATE_ORG, SEND_INVITE_MESSAGE } from './actionTypes';

import { openOrgPop } from './functions'

export function openOrgList(getState: ?Function): Function {
	const roomName = getState()['features/base/conference'].room;
	const deptId = getState()['features/xclick/userinfo'].deptid;
	const companyId = getState()['features/xclick/userinfo'].companyid;
	const inviteUser = getState()['features/xclick/userinfo'].displayname;
	const inviteUserId = getState()['features/xclick/userinfo'].loginid;

	openOrgPop(roomName, deptId, companyId, inviteUser, inviteUserId);
}

export function closeOrgList(): Function {
	
}
